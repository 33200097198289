.task-icon {
  height: 24px;
  width: 24px;
  position: absolute;
  left: 16px;
  top: 16px;
}

.close-icon {
  height: 32px;
  width: 32px;
  position: absolute;
  right: 16px;
  top: 16px;
}

.dialog-paper {
  /* background-color: #efeeee; */
  border-radius: 8px;
  min-height: 90vh;
}

.detail-header {
  min-height: 32px;
  padding: 8px 52px 8px 56px;
  position: relative;
  z-index: 1;
}

.textarea-wrapper {
  padding: 12px 0 0;
}

.task-title {
  overflow: hidden;
  overflow-wrap: break-word;
  border-radius: 4px;
  box-shadow: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: -6px -10px;
  min-height: 24px;
  padding: 6px 10px;
  resize: none;
  border: none;
  /* height: 445.333px; */
  height: 100%;
  width: 100%;
}

.task-title:focus {
  background-color: #fff;
  border: 2px solid #00ab55;
  padding: 5px 8px;
  background-color: #f5f5f5;
}

.window-main-col {
  float: left;
  margin: 0;
  min-height: 24px;
  padding: 0 8px 8px 16px;
  position: relative;
  z-index: 1;
}

.no-description-div {
  /* display: inline-block; */
  font-weight: 500;
  background-color: #f5f5f5;
  border-radius: 3px;
  min-height: 60px;
  padding: 1em;
}

.no-description-div:hover {
  /* display: inline-block; */
  font-weight: 500;
  background-color: #f1f1f1;
  border-radius: 3px;
  min-height: 60px;
  padding: 1em;
  cursor: pointer;
}

.comment-name {
  font-weight: 600;
}

.comment-data {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 8px 12px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.edit-delete-button {
  text-decoration: underline;
  cursor: pointer;
}

.sidebar-button {
  background-color: #f5f5f5;
  padding: 6px 12px;
  border-radius: 5px;
  font-weight: 600;
  justify-content: space-between;
}

.sidebar-button:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.add-comment-field:hover {
  cursor: pointer;
  background-color: #f7f7f7;
}

.checklist-item-title {
  overflow: hidden;
  overflow-wrap: break-word;
  border-radius: 4px;
  box-shadow: none;
  height: 38px;
  padding: 6px;
  resize: none;
  border: none;
  width: 100%;
  border: 1px solid #00ab55;
}

.checklist-item-title:focus {
  border: 1px solid #00ab55;
  padding: 6px;
}

.check-list-item {
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
  min-height: 32px;
}

.edit-checklist-div {
  background-color: #F5F8FA;
  border-radius: 3px;
}

.add-new-check-item {
  margin-left: 53px;
  margin-top: 3px;
  padding: 8px;
  background-color: #F5F8FA;
  border-radius: 4px
}

.add-check-list-btn {
  font-size: 12px;
  font-weight: 600;
  padding: 4px 12px;
  border-radius: 3px;
  min-height: 45px;
  margin-left: 53px;
  margin-top: 3px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F5F8FA;
}

.add-check-list-btn:hover {
  color: #00ab55;
}


.ps-2.ms-1.d-flex.justify-content-between.check-list-item.w-100:hover {
  position: relative;
}

.ps-2.ms-1.d-flex.justify-content-between.check-list-item.w-100:hover .hiddenDiv {
  display: block;
}

/* .hiddenDiv {
  display: none;
  position: absolute;
  right: 5px;
} */
.react-datepicker-popper {
  z-index: 1400 !important;
}

.sidebar-button .MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.MuiAvatarGroup-avatar.css-1b6xdos-MuiAvatar-root-MuiAvatarGroup-avatar {
  height: 25px !important;
  width: 25px !important;
}

.comment-data p {
  margin: 0 !important;
}

.comment-time {
  font-size: 12px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 2px;
}

.sidebar-button .css-t71pop {
  height: 25px !important;
  width: 25px !important;
}

/* ----------- checklist  */
.checklist .item-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.checklist .item-name:hover {
  color: #00ab55;
}

.checkbox-loader-div {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 11px 10px 10px;
}

.delete-icon {
  height: 16px;
  width: 16px;
}

.delete-icon:hover {
  color: #c23232;
}

/* ------------- activity */

.activity-stack {
  padding: 4px 8px;
  border-radius: 6px;
  cursor: pointer;
}

.task-activity .activity-stack:hover {
  background-color: #F5F8FA;
}

.activity-stack .img-div-avtar {
  position: relative;
}

.activity-stack .img-div-avtar::after {
  content: "";
  position: absolute;
  left: 49%;
  top: 35%;
  width: 1px;
  height: calc(100% + 15px);
  background-color: #efefef;
  transform: translateX(-50%);
}

.activity-stack:last-child .img-div-avtar::after {
  display: none;
}

.date-update {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  background-color: #f6f7f8;
  min-height: 36px;
  border-radius: 5px;
  font-weight: 600;
}

.arrow-icon-activity {
  height: 20px;
  width: 20px;
  color: #666;
  margin-top: 20px;
}

.img-div-avtar {
  margin-top: 3px;
}

.activity-user-name {
  font-weight: 600 !important;
  padding-right: 3px;
}

.arrow-icon-down-activity {
  height: 20px;
  width: 20px;
  color: #666;
}

.activity-paper {
  padding: 6px;
  border-radius: 4px;
  background-color: #f6f7f8;
}

.activity-title {
  font-size: 13px;
}

.activity-member {
  width: 200px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 6px 12px;
  background-color: #f6f7f8;
  min-height: 36px;
  border-radius: 5px;
}

.activity-description {
  padding: 12px;
  border-radius: 4px;
  background-color: #f6f7f8;
}
.activity-description img {
  max-height: 100px;
  max-width: 100px;
  object-fit: contain;
}

.description-text {
  font-size: 13px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.time-update {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  background-color: #f6f7f8;
  min-height: 36px;
  border-radius: 5px;
}

.notification-mail-icon {
  color: #919eab;
}

/* --------------------- Comments Notify User*/
.notify-selected-users {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.notify-selected-users .MuiToggleButton-root {
  /* private / public */
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 4px;
  margin-top: 6px;
}

.custom-radio{
  padding: 0px 8px;
  border-radius: 5px;
}
.custom-radio:hover {
  background-color: #f3f3f3;
}

.notify-selected-users .MuiRadio-root{
  /* radio button */
  padding: 0px;
  margin-right: 6px;
}

.notify-selected-users .MuiRadio-root:hover {
  background-color: transparent;
}
.notify-selected-users .MuiFormControlLabel-root{
  margin-right: 8px;
}

.custom-checkbox {
  padding: 0px;
  margin-right: 5px;
}

.custom-checkbox:hover {
  background-color: transparent;
}
.user-card {
  padding: 5px 10px 5px 8px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 4px;
  background-color: #f7f7f7;
}

.user-card:hover {
  background-color: #f3f3f3;
}

.checklist-div .accordion-Summary {
  padding: 0px 0px;
}

.checklist-div .MuiAccordionSummary-expandIconWrapper {
  margin-top: 8px !important;
}

.checklist-div .MuiAccordionDetails-root {
  padding: 0px;
}

.checklist-div .expand-more-Icon {
  background-color: #f5f8fa;
  border-radius: 50%;
}
.checklist-avatar-container .MuiAvatarGroup-avatar {
  height: 20px;
  width: 20px;
  font-size: 12px;
}

.choose-target-date:hover {
  color: #00ab55;
}