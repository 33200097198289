.page-header {
  margin-bottom: 1.5rem;
}

.page-title {
  font-size: 27px;
}

@media (min-width: 600px) {
  .page-title {
    font-size: 29px;
  }
}


.new-note-btn-container {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.notes-preview-card{
  border-radius: 5px;
  border: 1px solid #a7a5a5;
}

.note-card {
  /* height: 100%;
    width: 100%; */
  cursor: pointer;
  padding: 15px;
  border: 1px solid transparent;
}

.note-card:hover {
  background-color: #EBF8F2;
  border: 1px dashed #00AB55;
}

.card-content {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-description {
  margin-top: 0.25rem;
  overflow: hidden;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.43;
  text-decoration: none;
  font-style: normal;
  display: -webkit-box;
  max-height: 300px;
  -webkit-line-clamp: 17;
  /* for normal card  -------*/
  /* min-height: 70px;  */
  /* -webkit-line-clamp: 4; */
  -webkit-box-orient: vertical;
}

.card-description p {
  margin-bottom: 0px !important;
}

.notes-dialog{
  width: 100% !important;
  max-width: 850px !important;
  min-height: 650px !important;
  border-radius: 10px;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden !important;
}

.notes-dialog img{
  max-width: 100% !important;
  height: auto !important;
}

.description-box img, .masonry-container img {
  max-width: 100% !important;
  height: auto !important; 
  display: block;
}

/* Header */
.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px 5px 24px;
}


.note-model-full{
  height: 100%;
}
.no-scroll-notes {
  overflow: hidden !important;
}


/* Content */
.dialog-content {
  width: 100%;
  height: 100%;
  margin: 5px 0;
  padding: 0px 24px !important;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.description-box {
  border: 1px solid #e0e0e0;
  padding: 16px;
  border-radius: 8px;
  font-size: 13px;
  line-height: 1.2;
  height: 440px;
  overflow-x: hidden;
  overflow-y: auto;
}

.metadata {
  margin-top: 10px;
  padding-top: 10px;
  /* border-top: 1px solid #e0e0e0; */
}

.metadata-item {
  display: flex;
  justify-content: space-between;
}

/* .label {
  color: #212B36;
} */

/* .value {
  color: #637381;
} */

/* Actions */
.dialog-actions {
  padding: 8px 24px 16px 0;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
