.common-border {
  border: solid 1px transparent;
  border-radius: 12px;
}

.chat-container {
  /* background-color: #f4f6f8; */
  background-color: #ffffff;
  border: 1px solid rgb(177, 177, 177);
}

.chat-users {
  height: calc(100vh - 80px);
  background-color: #ffffff;
  border-right: solid 1px rgba(128, 128, 128, 0.479);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  overflow-y: hidden;
  padding: 0 15px;
}

.chat-messages-parent {
  height: calc(100vh - 165px);
  /* background-color: #f4f6f8; */
  background-color: #ffffff;
  padding: 2px 15px;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.chat-messages {
  display: block;
  background-color: rgb(255, 255, 255);
  padding: 1px;

}

.chat-messages-full {
  display: none;
  background-color: rgb(255, 255, 255);
  padding: 1px;
}

.chat-list-button-container {
  display: none;
  position: relative;
  bottom: 0.5rem;
  left: -0.5rem;
}

.chat-users-hidden {
  display: none;
}

@media screen and (max-width: 768px) {
  .chat-messages-full {
    display: block;
  }

  .chat-messages {
    display: none;
  }

  .chat-users {
    display: none;
  }

  .chat-list-button-container {
    display: block;
  }

  .chat-users-hidden {
    display: block;
    height: calc(100vh - 160px);
  }
}

/* .chat-tabs {
  margin-top: 1rem;
} */
.user-card-parent {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  /* border: solid 1px black; */
  padding: 0 7px;
  cursor: pointer;
}

.user-card-parent:hover {
  background-color: #3dda791c;
}

.messages-count {
  position: relative;
  right: 17px;
}

.center-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.btn-container {
  height: calc(100vh - 170px);
  position: relative;
  margin-right: 7px;
}

.add-chat-btn {
  background-color: #1C1C1E;
  position: absolute;
  right: 0;
  bottom: 38px;
  z-index: 1;
}

.add-chat-btn:hover {
  background-color: rgb(80, 80, 80);
}

.divider-content{
  font-size: small;
  margin-right: 12px;
}

/* @media screen and (max-width: 768px) {
  .chat-messages-parent {
    height: calc(100vh - 165px);
  }

  .messages-container {
    height: 71.5vh;
  }
} */

.group-menu-icon {
  position: relative;
  bottom: 6px;
}

.group-menu-icon {
  position: relative;
  bottom: 6px;
}

.ml-auto {
  margin-left: auto !important;
}

.messages-container {
  height: calc(100vh - 230px);
  /* overflow-y: ; */
  z-index: 1;
  margin-bottom: 4px;
  /* height: 54vh; */
  /* background: #828282; */
}

.messages-container-margin{
  height: calc(100vh - 290px);
}

.messages-container-file{
  height: calc(100vh - 384px);
}

.messages-container-both{
  height: calc(100vh - 444px);
}

.load-more-chat {
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 3;
  width: 100%;
}

.scroller {
  height: inherit;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroller::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.scroller::-webkit-scrollbar {
  width: 0.4rem;
  background-color: #f5f5f5;
}

.scroller::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #bebebe;
  /* background-color: #8383839c; */
}

.sent-message {
  background-color: #D9FDD3;
  border-radius: 10px 0px 10px 10px;
  position: relative;
  right: 10px;
  color: #111b21;
}

/* .sent-message:before {
  content: "";
  position: absolute;
  border: 5px solid transparent;
  border-left-color: #000000;
  border-top-color: #000000;
  left: auto;
  right: 0;
  bottom: -10px;
} */

.reply-item {
  width: 100%;
  background: rgba(128, 128, 128, 0.1);
  height: 50px;
  position: relative;
  padding: 0.3rem;
  cursor: pointer;
  border-left: 5px #515ecc solid;
  border-radius: 8px;
  margin-bottom: 2px;
  margin-left: 3px;
  /* padding: 0.3rem;
  padding-top: 1rem;
  padding-bottom: 0.2rem;
  min-width: 15rem;
  max-width: 60%;
  font-size: 14.2px;
  line-height: 19px;
  color: rgb(33, 33, 33);
  background-color: rgb(187, 247, 187);
  position: relative;
  bottom: 0px;
  right: 10px; */
}

.reply-flex{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 5px;
}

.user-profile{
  color: #515ecc;
  padding: 0;
  margin-left: 0;
}


.message-item {
  padding: 0.3rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  position: relative;
  min-width: 15rem;
  max-width: 60%;
  font-size: 14.2px;
  line-height: 19px;
  color: rgb(33, 33, 33);
}

.message-item p {
  margin: 8px 4px !important;
}

.message-item ol {
  margin-left: 1rem;
}

.message-item ul {
  margin-left: 1.2rem;
}

.message-item img {
  margin: auto;
}

.mr-1 {
  margin-right: 4px !important;
}

.clickable-link {
  font-size: 14px;
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
}

.message-margin {
  margin-top: 0.7rem;
  /* margin-bottom: 0.7rem; */
}

.received-message {
  background-color: #F1F1F1;
  border-radius: 0 10px 10px 10px;
  position: relative;
}

/* 
.received-message:before {
  content: "";
  position: absolute;
  border: 6px solid transparent;
  border-left-color: #F1F1F1;
  border-top-color: #F1F1F1;
  left: 0;
  right: auto;
  bottom: -13px;
} */

.chat-bottom-bar {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.message-editor {
  width: 100%;
  /* bottom: 15px; */
  /* bottom: -3.8vh; */
}

.message-editor .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  height: 13.5vh !important;
}

.message-editor .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  height: 13.5vh !important;
  border: solid 1px #3ab469;
}

.message-editor ul,
ol {
  margin-left: 1rem;
}

.text-area-parent {
  position: relative;
  width: 100%;
}

.send-message-area {
  width: 100%;
  height: 3rem;
  resize: none;
  padding-left: 11px;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  background-color: #EBEBEB;
  border: none;
  position: relative;
  border-radius: 10px;
}

.send-message-area:active,
:focus,
:focus-visible,
:focus-within {
  outline: none;
}

.send-message-area::-webkit-scrollbar {
  max-height: 0.28rem !important;
  max-width: 0.28rem;
}

.send-message-area::-webkit-scrollbar-track {
  background: rgba(233, 233, 233, 0);
}

.send-message-area::-webkit-scrollbar-thumb {
  background-color: #a5a5a57e;
  border-radius: 20px;
}

.send-icon-parent {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .new-msg-sidebar-mobile {
    position: relative;
    top: 0px;
  }
}

.team-list-parent {
  border-radius: 4px;
  cursor: pointer;
  justify-content: start;
  align-items: center;
}

.chat-avatar {
  height: 25px;
  width: 25px;
  border: solid 1px rgb(126, 126, 126);
}
.chat-avatar-big {
  height: 36px;
  width: 36px;
  border: solid 1px rgb(126, 126, 126);
}

.chat-avatar-large {
  height: 40px;
  width: 40px;
  border: solid 1px rgb(126, 126, 126);
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.m-0 {
  margin: 0 !important;
}

.floating-image-bar {
  position: absolute;
  /* background-color: #f4f6f8; */
  height: 52px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 97.8%;
  bottom: 57px;
  z-index: 2;
}

.message-image-container {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.3rem;
  height: 50px;
  overflow: hidden;
  padding: 0.1rem;
  margin-bottom: 0.1rem;
  position: relative;
}

.message-image-cross {
  position: absolute;
  color: rgba(255, 0, 0, 0.808);
  top: -2px;
  left: 43px;
  cursor: pointer;
  z-index: 4;
}

.pr-0 {
  padding-right: 0px !important;
}

.add-chat-btn-hidden {
  background-color: rgb(78, 78, 78);
  position: absolute;
  right: 11px;
  bottom: 19px;
}

.add-chat-btn-hidden:hover {
  background-color: rgb(87, 87, 87);
}

/* Custom Scrollbar */
.container-with-scrollbar {
  position: relative;
  overflow: hidden;
  width: calc(100% + 16px);
  /* Add extra width to accommodate the scrollbar */
}

.container-with-scrollbar:hover {
  overflow: auto;
  /* Display the scrollbar on hover */
}

.container-with-scrollbar::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
}

.container-with-scrollbar:hover::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
}

.container-with-scrollbar:hover::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Background color of the scrollbar track */
}

.container-with-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the scrollbar thumb */
  border-radius: 4px;
  /* Rounded corners of the scrollbar thumb */
}

.container-with-scrollbar:hover::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Color of the scrollbar thumb on hover */
}

.image-div {
  position: absolute;
  width: 100%;
  bottom: calc(100% + 60px);
  display: flex;
  align-items: start;
  justify-content: start;
  height: 150px;
  background-color: #EBEBEB;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: 1px solid darkgray;
}

/* chat Improvement */

.voice-recorder-container {
  box-shadow: none;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: #EBEBEB;
  border-radius: 10px;
}

.audio-recorder{
  box-shadow: none;
  border-radius: 10px;
}

.no-elevation{
  box-shadow: none;
}

.hide-borRadius{
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.react-mic {
  width: 100% !important;
  height: 20px !important;
  min-height: 20px !important;
  max-height: 20px !important;
}

.recording {
  width: 100%;
}

.recording-container {
  width: 100%;
}

.chat-date {
  color: #667781;
  line-height: 15px;
  font-size: 11px;
  font-weight: 400;
  text-align: end;
}

.audio-timer {
  position: absolute;
  left: 10px;
  bottom: 10px;
  font-size: smaller;
  font-weight: 600;
}

.audio-recorder img {
  display: none;
}

.audio-recorder {
  height: 100%;
}



.chat-user-icons {
  width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;
  margin-right: 5px;
  position: relative;
  bottom: 1px;
}

.full-chat-container {
  border-radius: 20px;
}

.hide-overflow {
  overflow: hidden;
}

.sidebar-container {
  margin: 22px 0;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-sidebar {
  margin: 7px 0 0 0;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-button-padding {
  padding: 0;
  margin: 0;
}

.icon-color {
  color: black;
}

.height-full {
  height: 550px;
}

.circular-style {
  height: 50%;
  border-radius: 50%;
}

.list-item {
  padding: 0.2rem;
}

.typography {
  font-size: 0.8rem;
}

.icon {
  margin: 0;
  margin-left: 1rem;
}

.sender-message-position {
  position: relative;
}

.sender-message-info {
  display: flex;
  justify-content: space-between;
  width: 24px;
  position: absolute;
  right: 47px;
  top: -8px;
}

.receiver-message-info {
  display: flex;
  justify-content: space-between;
  width: 50px;
  position: absolute;
  left: 41px;
  top: -8px;
}

.sender-text {
  font-size: 13px;
  font-weight: 600;
  text-align: end;
}

.sender-avatar {
  position: absolute;
  right: -47px;
  top: -3px;
}

.receiver-avatar {
  position: absolute;
  left: -41px;
}

.receiver-avatar {
  position: absolute;
  left: -41px;
}

.message-options {
  position: relative;
  right: 6px;
}

.chat-item-img {
  width: 225px;
  object-fit: fill;
  max-width: 300px;
  height: auto;
  max-height: 300px;
}


.scroll-div {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}

.infinite-scroll {
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
}

.circular-progress {
  width: 18px;
  height: 18px;
  margin: auto;
  position: relative;
  top: 30px;
}

.divider-pad{
  padding: 5px 0;
}

.circular-list{
  position: absolute;
  top: 40%;
  left: 40%;
}

.chat-bottom-container {
  margin-top: 8px;
  height: 65px;
}

.chat-bottom-container-margin{
  margin-top: 68px;
}

.chat-bottom-container-file{
  margin-top: 162px;
}
.chat-bottom-container-both{
  margin-top: 222px;
}

/* .icon-button-upload {
  color: #1C1C1E;
  padding: 0;
} */

.textarea-btn{
  padding: 0;
}

.voice-icon {
  width: 22px;
  height: 22px;
  color: #6b7c8b;
}

.reply-btn{
  position: absolute;
  left: 100%;
  top: 30%;
}
.send-reply-btn{
  position: absolute;
  right: 100%;
  top: 30%;
}

.reply-effect-container {
  transition: background-color 3s ease-out;
}
.reply-effect{
  background-color: lightgreen;
}

.group-image {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.bottom-component {
  padding-bottom: 10px;
}


.group-name-container {
  padding-left: 0px;
  padding-right: 0px;
}

.chat-paper {
  border-radius: 12px;
  background-color: transparent;
}

.chat-background {
  border-radius: 10px;
  height: 65px;
}

.chat-active {
  background-color: #D9FDD3;
}

.avatar-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 42px);
}

.chat-label {
  width: 67%;
}

.chat-label-text {
  color: #1E1E1E;
  font-weight: 600;
  font-size: 13px;
  white-space: nowrap;
}

.chat-last-message {
  color: #8D8F90;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
}

.chat-user-icons {
  padding: 0;
  margin: 0;
  margin-right: 4px;
  position: relative;
  left: -1px;
}

.chat-icon-reply{
  width: 14px;
  height: 14px;
}

.message-timestamp {
  color: #504f8a;
  font-weight: 600;
  font-size: 11px;
  white-space: nowrap;
}

.unread-count-badge {
  background-color: black;
  color: white;
  font-size: 0.4rem;
  height: 17px;
  min-width: 17px;
  padding: 5px;
}

.empty-div{
  height: 15px;
}

.message-count-container {
  width: 30%;
  text-align: end;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 85%;
}


.card-positioning{
  position: relative;
}

.chat-header-container {
  margin-bottom: 2px;
  border-radius: 10px 10px 0 0;
  padding: 0 15px;
}

.header-content {
  padding: 5px 0;
}

.chat-header-btn {
  margin-right: 1rem;
  padding: 0;
}

.badge-container {
  margin: 10px 10px 10px 0;
}

.chat-header-left {
  display: flex;
  align-items: center;
}

.chat-header-text {
  margin-left: 12px;
}

.chat-info-text {
  font-size: 11px;
  font-weight: 400;
}

.delete-button {
  padding: 0;
}

.info-button {
  padding: 0;
  margin-left: 5px;
}

.search-user-list {
  margin-bottom: 2rem;
}

.search-input-container {
  padding-left: 12px;
  padding-right: 12px;
}

.search-input {
  width: 100%;
}

.top-mar{
  margin-top: 20px;
}

.chat-drawer-position{
  position: relative;
}

.grp-load{
  margin-top: 50px;
}

.textarea-container{
  position: relative;
}

.reply-style{
  font-size: 11px;
  font-weight: 400;
}

.textarea-reply-container{
  height: 70px;
  top: -70px;
  position: absolute;
  width: 100%;
  background-color: #EBEBEB;
  padding: 3px 8px;
  border-radius: 10px 10px 0 0;
}

.textarea-reply{
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 3px 5px;
  width: 100%;
  height: 55px;
  background-color: #C4CDD5;
  cursor: default;
}

